/* ##remfixer: 1080 */
.News {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.News .text {
  width: 5.555556rem;
  margin-top: 0.37037rem;
}
.News .app-title {
  margin-bottom: 0.166667rem;
}
.content {
  width: 100%;
  overflow: hidden;
}

/*# sourceMappingURL=NewsList-vue.c9ea213d.css.map*/